import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const LoginPage = React.lazy(() => import('./pages/LoginPage'));
const Home = React.lazy(() => import('./pages/HomePage'));
const SpacePage = React.lazy(() => import('./pages/SpacePage'));
const SpaceDetailsPage = React.lazy(() => import('./pages/SpaceDetailsPage'));
const WorkflowsPage = React.lazy(() => import('./pages/WorkflowsPage'));
const ModuleEditor = React.lazy(() => import('./components/ModuleEditor'));
const DiagramPage = React.lazy(() => import('./components/DiagramPage'));
const WorkflowRunnerPage = React.lazy(() => import('./components/WorkflowRunnerPage')); // Dodano import nowej strony

export default function AppRoutes() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/spaces" element={<SpacePage />} />
                    <Route path="/spaces/:id" element={<SpaceDetailsPage />} />
                    <Route path="/workflows" element={<WorkflowsPage />} />

                    <Route
                        path="/modules/create"
                        element={
                            <DndProvider backend={HTML5Backend}>
                                <ModuleEditor />
                            </DndProvider>
                        }
                    />
                    <Route
                        path="/modules/:moduleId"
                        element={
                            <DndProvider backend={HTML5Backend}>
                                <ModuleEditor />
                            </DndProvider>
                        }
                    />

                    <Route
                        path="/workflows/create"
                        element={
                            <DndProvider backend={HTML5Backend}>
                                <DiagramPage />
                            </DndProvider>
                        }
                    />
                    <Route
                        path="/workflows/:id/edit"
                        element={
                            <DndProvider backend={HTML5Backend}>
                                <DiagramPage />
                            </DndProvider>
                        }
                    />
                    <Route
                        path="/diagram/:id"
                        element={
                            <DndProvider backend={HTML5Backend}>
                                <DiagramPage />
                            </DndProvider>
                        }
                    />
                    <Route
                        path="/workflowrun/:id" // Dodano nową trasę
                        element={<WorkflowRunnerPage />} // Nowy komponent strony
                    />
                </Routes>
            </Suspense>
        </Router>
    );
}
