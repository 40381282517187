import React from 'react';
import { ApolloProvider } from '@apollo/client';
import client from './apolloClient';
import AppRoutes from './routes';

function App() {
    return (
        <ApolloProvider client={client}>
            <AppRoutes />
        </ApolloProvider>
    );
}

export default App;
