import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const httpLink = createHttpLink({
    uri: 'https://myskfastapiapp-bb2a0bcb24f1.herokuapp.com/graphql', // Zamień na adres swojego GraphQL API
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('authToken');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );

            if (message === "Signature has expired") {
                // Jeśli token wygasł, usuń token z localStorage i przekieruj na stronę logowania
                localStorage.removeItem('authToken');
                localStorage.removeItem('userId');
                localStorage.removeItem('email');
                window.location.href = '/login'; // Przekierowanie do strony logowania
            }
        });
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
});

export default client;
